<template>
  <div>
    <a-result status="404" title="404" sub-title="Sorry, the page you visited does not exist.">
    <template #extra>
      <router-link to="/">
        <a-button type="primary"> Back to Dashboard </a-button>
      </router-link>
    </template>
  </a-result>
  </div>
</template>

<script>
export default {
  name: "Error404",
};
</script>
